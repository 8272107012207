import React, { useState } from "react"
import axios from "axios"
import * as FaIcons from "react-icons/fa"
import LoadingOverlay from "./LoadingOverlay"

const ContactForm = ({ showMessage, setStatus, setType, showForm }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    website: "",
    email: "",
  })

  const { name, organization, website, email } = formData

  const onChangeText = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      organization: "",
      website: "",
      email: "",
    })
  }
  const onSubmit = async e => {
    setShowSpinner(true)
    e.preventDefault()
    const data = {
      name,
      organization,
      website,
      email,
    }
    const constData = {
      first_name: name.split(" ")[0],
      last_name: name.split(" ")[1],
      email_address: email,
      organization,
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const res = await axios.post(
        `https://llac-static-site-api.azurewebsites.net/api/email/good4_join`,
        data,
        config
      )
      const constRes = await axios.post(
        `https://llac-static-site-api.azurewebsites.net/api/constant/goodsheet`,
        constData,
        config
      )

      showForm(false)
      setStatus(true)
      setType(true)
      resetForm()
      setShowSpinner(false)
      showMessage(true)
    } catch (err) {
      console.log(err.message)
      setShowSpinner(false)
      showForm(false)
      setStatus(false)
      resetForm()
      showMessage(true)
    }
  }

  return (
    <div id="join-us-modal">
      <form onSubmit={onSubmit} className="contact-us-card">
        <FaIcons.FaTimes
          id="join-us-close-icon"
          onClick={() => showForm(false)}
        />
        <h2>Join Us</h2>
        <div className="form">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={name}
            onChange={e => {
              onChangeText(e)
            }}
            required
          />
          <input
            type="text"
            name="organization"
            placeholder="Your Organization"
            value={organization}
            onChange={e => {
              onChangeText(e)
            }}
            required
          />
          <input
            type="text"
            name="website"
            placeholder="Orginization Website"
            value={website}
            onChange={e => {
              onChangeText(e)
            }}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={e => {
              onChangeText(e)
            }}
            required
          />

          <input type="submit" value="Send" className="btn btn-yellow" />
        </div>
      </form>
      {showSpinner && <LoadingOverlay color="BLUE" />}
    </div>
  )
}

export default ContactForm
