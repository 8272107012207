import React, { useState } from "react"
import axios from "axios"
import LoadingOverlay from "./LoadingOverlay"

const NewsLetterForm = ({ showMessage, setStatus, setType }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email_address: "",
  })
  const { name, email_address } = formData
  const onChangeText = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      email_address: "",
    })
  }
  const onSubmit = async e => {
    setShowSpinner(true)
    e.preventDefault()
    const first_name = name.split(" ")[0]
    const last_name = name.split(" ")[1]
    const data = {
      first_name,
      last_name,
      email_address,
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      // console.log(data)
      const res = await axios.post(
        "https://llac-static-site-api.azurewebsites.net/api/constant/newsletter",
        data,
        config
      )
      console.log(res.status)
      setStatus(true)
      setType(false)
      resetForm()
      setShowSpinner(false)
      showMessage(true)
    } catch (err) {
      console.error({ err: err.response.data })
      setShowSpinner(false)
      setStatus(false)
      resetForm()
      showMessage(true)
    }
  }
  return (
    <form onSubmit={onSubmit} className="sign-up-card">
      <h2>Sign Up!</h2>
      <div className="form">
        <input
          type="text"
          name="name"
          value={name}
          onChange={e => {
            onChangeText(e)
          }}
          placeholder="Your Name"
        />
        <input
          type="email"
          name="email_address"
          value={email_address}
          onChange={e => {
            onChangeText(e)
          }}
          placeholder="Email Address"
          required
        />
        <input type="submit" value="Send" className="btn btn-blue" />
      </div>
      {showSpinner && <LoadingOverlay color="BLUE" />}
    </form>
  )
}

export default NewsLetterForm
