import React from "react"
import blueSpinner from "../images/spinner-blue.gif"
import yellowSpinner from "../images/spinner-yellow.gif"

const LoadingOverlay = ({ color }) => {
  return (
    <div className="loading-overlay">
      <img src={color === "BLUE" ? blueSpinner : yellowSpinner} alt="" />
    </div>
  )
}

export default LoadingOverlay
