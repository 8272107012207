import React, { useState } from "react"
import ContactForm from "../components/ContactForm"
import JoinUsForm from "../components/JoinUsForm"
import NewsLetterForm from "../components/NewsLetterForm"
import MessageBanner from "../components/MessageBanner"

import Seo from "../components/seo"

const JoinUsPage = () => {
  const [showJoinForm, setShowJoinForm] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [msgSuccess, setMsgSuccess] = useState(true)
  const [isContact, setIsContact] = useState(true)
  return (
    <>
      {showMessage && (
        <MessageBanner
          showBanner={setShowMessage}
          status={msgSuccess}
          type={isContact}
        />
      )}
      {showJoinForm && (
        <JoinUsForm
          showForm={setShowJoinForm}
          showMessage={setShowMessage}
          setStatus={setMsgSuccess}
          setType={setIsContact}
        />
      )}
      <section id="join-us">
        <Seo
          title="Join Us"
          description="Contact us, join our coalition or sign up for news and updates about improving educational equity for all students."
        />
        <div className="one smaller-text-center">
          <div className="container">
            <div className="content">
              <p>
                If you’re interested in supporting at-risk students and
                Opportunity Youth, please join our Coalition.
              </p>
              <div
                className="btn btn-yellow"
                onClick={() => setShowJoinForm(true)}
              >
                Join Us
              </div>
            </div>
          </div>
        </div>
        <div id="SignUp" className="two">
          <div className="container">
            <div className="content">
              <div className="left">
                <NewsLetterForm
                  showMessage={setShowMessage}
                  setStatus={setMsgSuccess}
                  setType={setIsContact}
                />
              </div>
              <div className="right">
                <p>
                  Sign up to receive news and updates about how reengaging
                  Opportunity Youth and supporting vulnerable students is good
                  for us all.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="three">
          <div className="container">
            <div className="content">
              <div className="left">
                <p>
                  For Media queries and all other questions, please contact us.
                </p>
              </div>
              <div className="right">
                <ContactForm
                  showMessage={setShowMessage}
                  setStatus={setMsgSuccess}
                  setType={setIsContact}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JoinUsPage
