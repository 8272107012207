import React, { useState } from "react"
import axios from "axios"
import LoadingOverlay from "./LoadingOverlay"

const ContactForm = ({ showMessage, setStatus, setType }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const { name, email, message } = formData

  const onChangeText = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    })
  }
  const onSubmit = async e => {
    setShowSpinner(true)
    e.preventDefault()
    const data = {
      name,
      email,
      message,
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const res = await axios.post(
        `https://llac-static-site-api.azurewebsites.net/api/email/good4_contact`,
        data,
        config
      )
      console.log(res.data)
      setStatus(true)
      setType(true)
      resetForm()
      setShowSpinner(false)
      showMessage(true)
    } catch (err) {
      console.log(err.message)
      setStatus(false)
      resetForm()
      setShowSpinner(false)
      showMessage(true)
    }
  }

  return (
    <form onSubmit={onSubmit} className="contact-us-card">
      <h2>Contact Us</h2>
      <div className="form">
        <input
          type="text"
          name="name"
          id=""
          placeholder="Your Name"
          value={name}
          onChange={e => {
            onChangeText(e)
          }}
          required
        />
        <input
          type="email"
          name="email"
          id=""
          placeholder="Email Address"
          value={email}
          onChange={e => {
            onChangeText(e)
          }}
          required
        />
        <textarea
          name="message"
          id=""
          rows="5"
          placeholder="Message"
          value={message}
          onChange={e => {
            onChangeText(e)
          }}
          required
        ></textarea>
        <input type="submit" value="Send" className="btn btn-yellow" />
      </div>
      {showSpinner && <LoadingOverlay color="BLUE" />}
    </form>
  )
}

export default ContactForm
